import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken } from "../../helpers/utility";
import actions from "./actions";
import { notification } from "../../components";
import {login,customerLogin} from "../../helpers/parseHelper";
import Swal from 'sweetalert2'


export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function*({ payload }) {
    try {
      const result = yield call(
        customerLogin,
        payload.userInfo
      );
      console.log('payload.userInfo.fromPath',payload.userInfo.fromPath);
      //console.log(`result  sessionToken : ${result.get('sessionToken')} | objectId : ${result.id}  | expireTime : ${result.get('expireTime')}`)
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result.get('sessionToken'),
        role: result.role,
        user_id: result.id,
        expireTime: result.get('expireTime')
      });
      yield put(push(payload.userInfo.fromPath));
    } catch (error) {
      //notification("error", error.message);
      Swal.fire({
        icon: 'error',
        title: 'ไม่พบข้อมูลในระบบ',
        //text: 'ไม่พบข้อมูลในระบบ',
      })
      yield put({
        type: actions.LOGIN_ERROR
      });
    }
  });
}

export function* jwtLoginRequest() {
  yield takeEvery(actions.JWT_LOGIN_REQUEST, function*({ payload }) {
    try {
      const result = yield call(
        login,
        payload.userInfo
      );
      //console.log(`result  sessionToken : ${result.get('sessionToken')} | objectId : ${result.id}  | expireTime : ${result.get('expireTime')}`)
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result.get('sessionToken'),
        role: result.role,
        user_id: result.id,
        expireTime: result.get('expireTime')
      });
      yield put(push("/admin"));
    } catch (error) {
      notification("error", error.message);
      yield put({
        type: actions.LOGIN_ERROR
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    //console.log(`loginSuccess  token : ${payload.token} | user_id : ${payload.user_id}  | expireTime : ${payload.expireTime}`)

    yield localStorage.setItem(
      "id_token",
      payload.token + "|" + payload.expireTime
    );
    yield localStorage.setItem("token", payload.token);
    yield localStorage.setItem("user_role", payload.role);
    yield localStorage.setItem("user_id", payload.user_id);
    yield localStorage.setItem("expireTime", payload.expireTime);

  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(push("/login"));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const id_token = getToken().get("id_token");
    const token = getToken().get("token");
    const role = getToken().get("role");
    const user_id = getToken().get("user_id");
    const expireTime = getToken().get("expireTime");
    //console.log(`checkAuthorization id_token: ${id_token} | token : ${token} | user_id : ${user_id}`)
    var currentTimestamp = Math.round((new Date()).getTime() / 1000);

    if (token && parseInt(id_token.split('|')[1]) > currentTimestamp ) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        role,
        user_id,
        id_token,
        expireTime
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(jwtLoginRequest),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
