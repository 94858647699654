const env = {}
const PROD = true
const URL = PROD?"https://auto.pokerth.co":'http://localhost'
env.url = URL
env.parseServerUrl = `${URL}:1337/parse`
env.appId = "a0036c4a-edd1-4d5b-abb6-ee75f860d500"
env.masterKey = "29ce01d3-4bfd-459e-8d82-2d8ef1bcd324"
env.javascriptKey = "46cc1edb-af2c-4eab-977e-f59a988f1b67"
env.lineURL = 'https://lin.ee/EGWBm6T'
env.appName = 'X-Poker Thailand'
env.version = "1.1.0"

env.provider = "XPOKER"
env.primaryColor = '#1c4c9e'
env.secondnaryColor = '#80a8ed'
env.headerColor = '#06132b'

export default env